import React, { useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { IconButton, CircularProgress, Tooltip, Button } from "@mui/material";
import Refresh from '@mui/icons-material/Refresh';
import axios from "axios";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import "./Topbar.scss";
import HOVDownloadBtn from "../hovDownloadBtn/HOVDownloadBtn";
import HOVMultiSelectDropdown from "../hovMultiSelectDropdown/HOVMultiSelectDropdown";
import HOVButton from "../hovButton/HOVButton";


function downloadCSV(csvContent, filename) {
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
const Topbar = (props) => {
  const [showRows, setShowRows] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const {
    header,
    refresh,
    refreshData,
    rowsPerPage,
    setRowsPerPage,
    handleToggleColumnVisibilty,
    dropdownOptions,
    selectedValuesProps,
    setVisibilityToStore,
    handleCreateOpen,
    onFilterTextBoxChanged,
    buttonLabel,
    create = false,
    edit = false,
    handleClose,
  } = props.value;

  const handleRowChange = (e) => {
    setRowsPerPage(e.target.value);
  };
  const handleRowClose = () => {
    setShowRows(false);
  };
  const handleRowOpen = () => {
    setShowRows(true);
  };

  const handleCSVDownload = () => {
    const baseUrl = process.env.REACT_APP_CSV_API_ENDPOINT
    const apiKey = process.env.REACT_APP_CSV_API_KEY
    const apiRoute = "v1/accounts"
    const downloadFileName = "hov_accounts_report.csv"
    const payload = {
      "filtered": "true",
      "account_ids_only": "false"
    }
    setIsDownloading(true)
    axios.post(`${baseUrl}/${apiRoute}`, payload,
      {
        headers: {
          "Content-Type": "text/csv",
          "x-api-key": `${apiKey}`
        }
      }).then((res) => {
        setIsDownloading(false)
        downloadCSV(res.data, downloadFileName)
      }).catch(err => {
        console.error(err.message)
        setIsDownloading(false)
      })
  }

  return (
    <div className={!create && !edit ? "topbar-main-container" : "topbar-main-container shrinked"}>
      <div className="heading">
        {
          edit || create ?
            (<span className="refresh-icon" onClick={handleClose} style={{ marginRight: 8 }}>
              <Tooltip title="Back" placement="top">
                <IconButton
                  style={{ color: "#424242", height: 35, width: 35 }}
                  disableRipple
                  size="large">
                  <KeyboardBackspaceIcon />
                </IconButton>
              </Tooltip>
            </span>)
            : ''
        }
        {header}
        {
          !edit && !create ?
            (<span className="refresh-icon" onClick={refreshData} style={{ marginLeft: 8 }}>
              <Tooltip title="Refresh" placement="top">
                <IconButton style={{ color: "#424242" }} disableRipple size="large">
                  {refresh
                    ? <CircularProgress size={20} color={"inherit"} />
                    : <Refresh />}
                </IconButton>
              </Tooltip>
            </span>)
            : ''
        }
      </div>
      {
        !edit && !create ?
          <div className="action-layout">
            <div className="search-bar">
              <input
                type="search"
                id="filter-text-box"
                placeholder="Filter..."
                onInput={onFilterTextBoxChanged}
              />
            </div>
            <div className="range-select">
              <div className="range-and-total-pages">
                <span>Number</span>
                <span className="mx-1">of</span>
                <span>rows</span>
              </div>
              <FormControl className="mx-1 row-select">
                <InputLabel id="demo-controlled-open-select-label"></InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  className="select-dropdown"
                  open={showRows}
                  onClose={handleRowClose}
                  onOpen={handleRowOpen}
                  value={rowsPerPage}
                  onChange={handleRowChange}
                  disableUnderline={true}
                  IconComponent={KeyboardArrowDownIcon}
                  variant="standard"
                >
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="pagination-pdf-btn-container">
              <div className="pagination-dropdown">
                <HOVMultiSelectDropdown
                  onChange={handleToggleColumnVisibilty}
                  dropdownOptions={dropdownOptions}
                  selectedValuesProps={selectedValuesProps}
                  setVisibilityToStore={setVisibilityToStore}
                />
              </div>
              <div className="button-container">
                <HOVButton
                  value={buttonLabel}
                  color="#fff"
                  backgroundColor="#00358E"
                  border="none"
                  custom
                  type="submit"
                  className="mx-1"
                  onClick={handleCreateOpen}
                />
                <Button
                  variant="contained"
                  className="HOV-download-btn"
                  endIcon={isDownloading ? (<CircularProgress size={20} color="inherit" />) : (<FileDownloadOutlinedIcon />)}
                  onClick={handleCSVDownload}
                >Download CSV</Button>
              </div>
            </div>
          </div>
          : ''
      }
    </div>
  );
}

export default Topbar;