import React, { useState, useEffect, useContext, useMemo } from 'react';
import {
  CircularProgress,
  Backdrop,
  IconButton
} from '@mui/material';
import { Add, Clear } from '@mui/icons-material';
import Topbar from '../../components/topbar/Topbar';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import HOVInput from '../../components/hovInput/HOVInput';
import AutoCompleteWithChips from '../../components/autoCompleteWithChips/AutoCompleteWithChips';
import HOVButton from '../../components/hovButton/HOVButton';
import DrawerContext from '../../context/DrawerContext';
import useApiCalls from '../../hooks/useApiCalls';
import { GlobalContext } from '../../App';

const AutoSubmitToken = (props) => {
  const {
    getInstanceTypes,
  } = useApiCalls();

  // Grab values and submitForm from context
  const { values } = useFormikContext();

  useMemo(() => {
    if (values.os_type !== '') {
      const os = values.os_type[0].value

      props.setBackdrop(true);

      getInstanceTypes(`instancetypes/${os}`, "Set_Instance_Types_List");

      values.instance_type = [];

      setTimeout(() => {
        props.setBackdrop(false);
      }, 8000);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.os_type]);
  return null;
};

const CreateRequest = (props) => {
  const controlsStyle = { maxWidth: '22vw' };
  const [backdropOpen, setBackdrop] = useState(false);
  const drawerState = useContext(DrawerContext).drawerState;
  const [inputs, setInputs] = useState([{ "accounts": "", "regions": [] }]);
  const [add, setAdd] = useState(false);
  const { state } = useContext(GlobalContext);
  const {
    addRecord,
    useHttpStatus,
    useMessage,
    useOpen,
    useSeverity
  } = useApiCalls();
  const {
    setOpen,
    setMessage,
    setSeverity,
    handleCreateRequestClose,
    create
  } = props;


  useEffect(() => {
    let suscribe = true;
    if (suscribe) {
      if (add) {
        setOpen(useOpen);
        setMessage(useMessage);
        setSeverity(useSeverity);

        if (useHttpStatus === 201) {
          setTimeout(() => {
            setBackdrop(false);
            handleCreateRequestClose();
          }, 2000);
        }
        else {
          setBackdrop(false);
        }
      }
    }

    return () => {
      suscribe = false;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useHttpStatus, useMessage, useOpen, useSeverity]);


  const handleFormChange = (e, index, fieldName) => {
    let newFormValues = [...inputs];
    newFormValues[index][fieldName] = e;
    setInputs(newFormValues);
  };

  const handleAddInput = () => {
    setInputs([...inputs, { accounts: "", regions: [] }])
  };

  const handleRemoveInput = (index) => {
    if (inputs.length !== 1) {
      let newFormValues = [...inputs];
      newFormValues.splice(index, 1);
      setInputs(newFormValues);
    }
  };

  const values = {
    header: "Create GI Request",
    create: create,
    handleClose: handleCreateRequestClose
  };


  return (
    <div className="page-table-container">
      <Topbar value={values} />
      <div
        className="form-container"
        style={{ height: 'fit-content', width: '90%' }}
      >
        <Formik
          initialValues={{
            bu_name: state.bizUnits?.data?.length === 1 ? [{
              label: state.bizUnits?.data[0].BusinessUnit,
              value: state.bizUnits?.data[0].BusinessUnit
            }] : [],
            os_type: '',
            s3: '',
            script: '',
            bu_ami_name: '',
            root_volume_size: '',
            instance_type: [],
            target_accounts_regions: []
          }}
          validationSchema={ValidationSchema}
          onSubmit={(values, actions) => {
            setBackdrop(true);
            setAdd(false);
            try {
              const keys = Object.keys(values);
              const keysLength = keys.length;

              let formatedValues = {
                bu_name: '',
                os_type: '',
                s3: '',
                script: '',
                bu_ami_name: '',
                root_volume_size: 0,
                instance_type: '',
                target_accounts_regions: []
              };

              // filtering only values and removing labels
              const filteredValues = keys.map((key) => {
                if (values[key] instanceof Array) {
                  return values[key].map(
                    (singleArrayValue) => singleArrayValue.value
                  );
                }
                return values[key];
              });

              // mapping filtered values to formatedValues
              for (let index = 0; index < keysLength; ++index) {
                let key = keys[index];
                formatedValues[key] = filteredValues[index];
              }
              // Check for script file extention
              let scriptExtention = formatedValues["script"].split(".");

              if (scriptExtention[scriptExtention.length - 1] !== 'sh' && scriptExtention[scriptExtention.length - 1] !== "ps1") {
                setOpen(true);
                setSeverity("error");
                setMessage("Script file can only have .sh or .ps1 extention");
                setBackdrop(false);
                return;
              }

              formatedValues["os_type"] = formatedValues["os_type"][0];
              formatedValues["bu_name"] = formatedValues["bu_name"][0];
              formatedValues["instance_type"] = formatedValues["instance_type"][0];

              // mapping target account ids with target regions
              for (let i = 0; i < inputs.length; i++) {
                if (inputs[i].regions.length > 0) {
                  if (inputs[i].accounts.length > 0) {
                    formatedValues["target_accounts_regions"].push({
                      account_id: inputs[i].accounts[0].value,
                      region: []
                    })

                    for (let j = 0; j < inputs[i].regions.length; j++) {
                      formatedValues["target_accounts_regions"][i].region.push(inputs[i].regions[j].value);
                    }
                  }
                  else {
                    setOpen(true);
                    setSeverity("error");
                    setMessage("Please select an account id");
                    setBackdrop(false);
                    return;
                  }
                }
                else {
                  setOpen(true);
                  setSeverity("error");
                  setMessage(`No region selected for account id ${inputs[i].accounts[0].value}`);
                  setBackdrop(false);
                  return;
                }
              };

              // array of target account ids
              let accountsList = [];

              formatedValues["target_accounts_regions"].forEach(item => {
                accountsList.push(item.account_id)
              });


              // check for duplicate account id
              let duplicates = accountsList.filter(
                (item, index) => accountsList.indexOf(item) !== index);

              if (duplicates.length > 0) {
                setOpen(true);
                setSeverity("error");
                setMessage(`Duplicate account id selected ${duplicates[0]}`);
                setBackdrop(false);
                return;
              }

              addRecord('request', formatedValues, 'SET_GI_DATA');
              setAdd(true);
            }
            catch (error) {
              setOpen(true);
              setMessage(error.message);
              setSeverity("error");
              setBackdrop(false);
            }
          }}
        >
          {(formikProps) => (
            <form onSubmit={formikProps.handleSubmit}>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 col-xl-4 mb-4">
                    <AutoCompleteWithChips
                      label="OS Type"
                      value={formikProps.values.os_type}
                      onChange={(e) => formikProps.setFieldValue(`os_type`, [e])}
                      name="os_type"
                      form={formikProps}
                      xstyle={controlsStyle}
                      option="os"
                      multi
                      required
                      description="Select the Operating System (OS) for the image"
                    />
                    {formikProps.errors.os_type &&
                      formikProps.touched.os_type ? (
                      <div className="text-danger">
                        {formikProps.errors.os_type}
                      </div>
                    ) : null}
                    <AutoSubmitToken setBackdrop={setBackdrop}/>
                  </div>
                  <div className="col-12 col-xl-4 mb-4">
                    <AutoCompleteWithChips
                      label="Business Unit"
                      value={formikProps.values.bu_name}
                      onChange={(e) => formikProps.setFieldValue(`bu_name`, [e])}
                      name="bu_name"
                      option="bu"
                      multi
                      required
                      description="Select Business Unit"
                    />
                    {formikProps.errors.bu_name &&
                      formikProps.touched.bu_name ? (
                      <div className="text-danger">
                        {formikProps.errors.bu_name}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 col-xl-4 mb-4">
                    <HOVInput
                      label="Image Name"
                      required
                      onChangeProp={formikProps.handleChange}
                      onBlurProp={formikProps.handleBlur}
                      valueProp={formikProps.values.bu_ami_name}
                      name="bu_ami_name"
                      description="Enter a name for the image"
                    />
                    {formikProps.errors.bu_ami_name &&
                      formikProps.touched.bu_ami_name ? (
                      <div className="text-danger">
                        {formikProps.errors.bu_ami_name}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 col-xl-4 mb-4">
                    <AutoCompleteWithChips
                      label="Instance Type"
                      value={formikProps.values.instance_type}
                      onChange={(e) => formikProps.setFieldValue(`instance_type`, [e])}
                      name="instance_type"
                      option="instanceTypes"
                      multi
                      required
                      description="Select Instance Type"
                    />
                    {formikProps.errors.instance_type &&
                      formikProps.touched.instance_type ? (
                      <div className="text-danger">
                        {formikProps.errors.instance_type}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 col-xl-4 mb-4">
                    <HOVInput
                      label="Root Volume Size(Gb)"
                      required
                      onChangeProp={formikProps.handleChange}
                      onBlurProp={formikProps.handleBlur}
                      valueProp={formikProps.values.root_volume_size}
                      name="root_volume_size"
                      description="Enter EBS root volume size for the image"
                    />
                    {formikProps.errors.root_volume_size &&
                      formikProps.touched.root_volume_size ? (
                      <div className="text-danger">
                        {formikProps.errors.root_volume_size}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 col-xl-4 mb-4">
                    <HOVInput
                      label="S3 Bucket"
                      required
                      onChangeProp={formikProps.handleChange}
                      onBlurProp={formikProps.handleBlur}
                      valueProp={formikProps.values.s3}
                      name="s3"
                      description="Enter the S3 bucket name where the script to be executed during image creation is stored"
                    />
                    {formikProps.errors.s3 &&
                      formikProps.touched.s3 ? (
                      <div className="text-danger">
                        {formikProps.errors.s3}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 col-xl-4 mb-4">
                    <HOVInput
                      label="Script"
                      required
                      onChangeProp={formikProps.handleChange}
                      onBlurProp={formikProps.handleBlur}
                      valueProp={formikProps.values.script}
                      name="script"
                      description="Enter the name of the script to be used for image creation"
                    />
                    {formikProps.errors.script &&
                      formikProps.touched.script ? (
                      <div className="text-danger">
                        {formikProps.errors.script}
                      </div>
                    ) : null}
                  </div>
                  {
                    inputs.map((item, index) => (
                      <div className="container-fluid" style={{ paddingLeft: 15 }} key={index}>
                        <div className="row">
                          <div className="col-12 col-xl-4 mb-4">
                            <AutoCompleteWithChips
                              label="Target Account ID"
                              value={item.accounts}
                              onChange={(e) => handleFormChange([e], index, "accounts")}
                              name="account_id"
                              option="account"
                              multi
                              required
                              description="Select the account ID for image sharing"
                            />
                          </div>
                          <div className="col-12 col-xl-4 mb-4">
                            <AutoCompleteWithChips
                              label="Target Regions"
                              value={item.regions}
                              onChange={(e) => handleFormChange(e, index, "regions")}
                              name="region"
                              option="region"
                              required
                              description="Choose the regions for image sharing"
                            />
                          </div>
                          <div style={{ marginTop: 30, marginLeft: 10 }}>
                            <IconButton
                              component="span"
                              onClick={handleAddInput}
                              style={{ color: "#424242", width: 40, height: 40 }}
                              disableRipple
                              size="large">
                              <Add />
                            </IconButton>
                          </div>
                          <div style={{ marginTop: 30, marginLeft: 15 }}>
                            <IconButton
                              component="span"
                              onClick={() => handleRemoveInput(index)}
                              style={{ color: "#424242", width: 40, height: 40 }}
                              disableRipple
                              size="large">
                              <Clear />
                            </IconButton>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
                <div className="d-flex align-items-center h-100 mt-3 mt-lg-0 justify-content-end button-layout">
                  <div>
                    <HOVButton
                      value="Create"
                      color="#fff"
                      backgroundColor="#008542"
                      border="none"
                      className="px-5 py-2"
                      buttonWidth={140}
                      custom
                      type="submit"
                      onClick={formikProps.handleSubmit}
                    />
                  </div>
                  <span className="mx-3"></span>
                  <div>
                    <HOVButton
                      value="Cancel"
                      color="#fff"
                      backgroundColor="#FF4E50"
                      border="none"
                      className="px-5 py-2"
                      buttonWidth={140}
                      custom
                      onClick={() => handleCreateRequestClose()}
                    />
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
        <Backdrop
          open={backdropOpen}
          style={{ zIndex: 100 }}
        >
          <CircularProgress
            style={drawerState ? {
              marginLeft: 200
            } : { margin: "0 auto" }}
          />
        </Backdrop>
      </div>
    </div>
  );
};

export default CreateRequest;

const ValidationSchema = Yup.object().shape({
  bu_name: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string().required(),
        label: Yup.string().required(),
      })
    ).min(1, 'Please select a business unit.'),
  os_type: Yup.array().required('Please select OS type.'),
  s3: Yup.string().matches(/^[a-z][a-zA-Z0-9-.]*$/, "Please provide valid S3 bucket name").required('Please provide S3 bucket name.'),
  script: Yup.string().matches(/^[a-zA-Z0-9].*/, "Please provide a valid script path").required('Please provide a script file name.'),
  bu_ami_name: Yup.string().required('Please provide an AMI name.'),
  instance_type: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string().required(),
        label: Yup.string().required()
      })
    ).min(1, 'Please select an instance type'),
  root_volume_size: Yup.string().matches(/^[0-9]*$/, "Root volume size can be number only").required('Please provide valid root volume size')
});