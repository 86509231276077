import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import {
  CircularProgress,
  IconButton,
  Backdrop,
  Tooltip
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import DeleteConfimation from '../../components/deleteConfirmation/DeleteConfirmation';
import DescriptionIcon from '@mui/icons-material/Description';
import StopIcon from '@mui/icons-material/StopCircleOutlined';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import { GlobalContext } from '../../App';
import DrawerContext from '../../context/DrawerContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import Error from '../../components/error/Error';
import HOVAlert from '../../components/hovAlert/HOVAlert';
import Topbar from '../../components/topbar/Topbar';
import Loader from '../../components/loader/Loader';
import TableActionButton from '../../components/tableActionButtons/TableActionButton';
import useApiCalls from '../../hooks/useApiCalls';

const BUGITable = (props) => {
  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(null);
  const [loading, setLoading] = useState(false);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [tableData, setTableData] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const { state, dispatch } = useContext(GlobalContext);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [id, setId] = useState(0);
  const [error, setError] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [backdropOpen, setBackdrop] = useState(false);
  const [del, setDel] = useState(false);
  const [snackPack, setSnackPack] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [dropdownOptions, setDropdownOptions] = useState([
    'BUSINESS UNIT',
    'OS TYPE',
    'SCRIPT',
    'INSTANCE TYPE',
    'PIPELINE STATUS',
    'LAST RUN',
    'STACK STATUS'
  ]);
  // eslint-disable-next-line no-unused-vars
  const [visibilityData, setVisibilityData] = useState({
    selectedValues: state.accountsTableColumnVisibility.selectedValues,
    deselectedValues: state.accountsTableColumnVisibility.deselectedValues,
  });
  const drawerState = useContext(DrawerContext).drawerState;
  const {
    getTableData,
    deleteRecord,
    getRequest,
    useOpen,
    useSeverity,
    useMessage,
    useErrorCode,
    useHttpStatus
  } = useApiCalls();
  const [style, setStyle] = useState({
    height: '100%',
    width: '99%',
  });

  // Initial api call to get table data
  useEffect(() => {
    let suscribe = true;
    if (suscribe) {
      getTableData('request', 'SET_GI_DATA');
    }

    return () => {
      suscribe = false;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setQuickFilter(
      document.getElementById('filter-text-box').value
    );
  }, []);


  // For closing the snackbar
  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }

  const handleExited = () => {
    setMessage(undefined);
  };;

  const refreshData = () => {
    setRefresh(true);
    getTableData('request', 'SET_GI_DATA');
  };

  useEffect(() => {
    let suscribe = true;
    if (suscribe) {
      props.setSeverity(useSeverity);
      props.setOpen(useOpen);
      props.setMessage(useMessage);

      if (useHttpStatus !== '') {
        setBackdrop(false);
      }
    }

    return () => {
      suscribe = false;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useSeverity, useMessage, useOpen, useHttpStatus]);

  useEffect(() => {
    let suscribe = true;
    if (suscribe) {
      if (useErrorCode) {
        setError(true);
        setErrorCode(useErrorCode);
      }
    }

    return () => {
      suscribe = false;
    }

  }, [useErrorCode]);

  useEffect(() => {
    let suscribe = true;
    if (suscribe) {
      setLoading(false);
      setRefresh(false);
      let tempBUData = state.bugiData?.data?.map((row) => {
        return {
          biz_unit: row.bu_name,
          biz_unit_ami_name: row.bu_ami_name,
          s3_bucket: row.s3,
          script: row.script,
          os_type: row.os_type,
          stack_status: row.stack_status,
          instance_type: row.instance_type,
          pipeline_status: row.pipeline_status,
          pipeline_last_run: row.pipeline_last_run,
          os_type_bu_ami_name: row.os_type_bu_ami_name
        };
      });
      let rdata = tempBUData?.map((row) => {
        return {
          ...row,
          editActionBtn: { EditActionBtn }
        };
      });
      setTableData(rdata);
    }

    return () => {
      suscribe = false;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.bugiData]);

  useEffect(() => {
    setWidthAndHeight('99%', '70vh');
  }, [drawerState]);

  useEffect(() => {
    gridApi?.sizeColumnsToFit();
  }, [gridApi]);

  useEffect(() => {
    async function setWidthAfterDrawerToggle() {
      await sleep(200);
      gridApi?.sizeColumnsToFit();
    }
    setWidthAfterDrawerToggle();
  });


  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

    // on init after leaving page
    state.accountTableColumnVisibility?.selectedValues?.forEach(
      (selectedValue) => toggleColumnsVisibility(selectedValue, true)
    );
    state.accountTableColumnVisibility?.deselctedValues?.forEach(
      (deselctedValue) => toggleColumnsVisibility(deselctedValue, false)
    );
    gridApi?.sizeColumnsToFit();
  };

  const setVisibilityToStore = (visibilityDataa) => {
    dispatch({ type: 'SET_COLUMNS_VISIBILITY', payload: visibilityDataa });
  };

  const toggleColumnsVisibility = (column, toggleState) => {
    switch (column) {
      case 'BUSINESS UNIT':
        gridColumnApi?.setColumnVisible('biz_unit', toggleState);
        break;
      case 'OS TYPE':
        gridColumnApi?.setColumnVisible('os_type', toggleState);
        break;
      case 'SCRIPT':
        gridColumnApi?.setColumnVisible('script', toggleState);
        break;
      case 'STACK STATUS':
        gridColumnApi?.setColumnVisible('stack_status', toggleState);
        break;
      case 'INSTANCE TYPE':
        gridColumnApi?.setColumnVisible('instance_type', toggleState);
        break;
      case 'PIPELINE STATUS':
        gridColumnApi?.setColumnVisible('pipeline_status', toggleState);
        break;
      case 'LAST RUN':
        gridColumnApi?.setColumnVisible('pipeline_last_run', toggleState);
        break;
      default:
        break;
    }
  };

  const handleToggleColumnVisibilty = (selectedValues, deselctedValues) => {
    selectedValues.forEach((selectedValue) =>
      toggleColumnsVisibility(selectedValue, true)
    );
    deselctedValues.forEach((deselctedValue) =>
      toggleColumnsVisibility(deselctedValue, false)
    );
    gridApi?.sizeColumnsToFit();
  };

  const setWidthAndHeight = (width, height) => {
    setStyle({
      width,
      height,
    });
  };

  useEffect(() => {
    if (snackPack.length && !message) {
      // Set a new snack when we don't have an active one
      setMessage({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && message && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackPack, message, open]);

  const handleCopyPaste = (message) => () => {
    navigator.clipboard.writeText(message);
    setSeverity("info");
    setSnackPack((prev) => [...prev, { message: "Text copied", key: new Date().getTime() }]);
  };

  const commonCellStyles = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    font: 'normal normal 100 0.9rem/24px Roboto'
  };

  const columnDefs = [
    {
      headerName: 'Business Unit',
      field: 'biz_unit',
      sortable: true,
      suppressSizeToFit: false,
      sortingOrder: ['asc', 'desc'],
      cellClassRules: {
        altBg: function (params) {
          return params.node.rowIndex % 2 === 0;
        },
      },
      suppressMenu: true,
      cellStyle: {
        ...commonCellStyles
      },
      cellRendererFramework: function (params) {
        return (
          <div className="text-decoration-none cursor-pointer execution-id"
            onClick={handleCopyPaste(params.value)}
          >
            {' '}
            {params.value}{' '}
          </div>
        )
      }
    },
    {
      headerName: 'OS Type',
      field: 'os_type',
      sortable: true,
      suppressSizeToFit: false,
      sortingOrder: ['asc', 'desc'],
      cellClassRules: {
        altBg: function (params) {
          return params.node.rowIndex % 2 === 0;
        },
      },
      suppressMenu: true,
      cellStyle: { ...commonCellStyles },
      cellRendererFramework: function (params) {
        return (
          <div className="text-decoration-none cursor-pointer"
            onClick={handleCopyPaste(params.value)}
          >
            {' '}
            {params.value}{' '}
          </div>
        )
      }
    },
    {
      headerName: 'Instance Type',
      field: 'instance_type',
      sortable: true,
      suppressSizeToFit: false,
      sortingOrder: ['asc', 'desc'],
      cellClassRules: {
        altBg: function (params) {
          return params.node.rowIndex % 2 === 0;
        },
      },
      suppressMenu: true,
      cellStyle: { ...commonCellStyles },
      cellRendererFramework: function (params) {
        return (
          <div className="text-decoration-none cursor-pointer"
            onClick={handleCopyPaste(params.value)}
          >
            {' '}
            {params.value}{' '}
          </div>
        )
      }
    },
    {
      headerName: 'Script',
      field: 'script',
      sortable: true,
      suppressSizeToFit: false,
      sortingOrder: ['asc', 'desc'],
      cellClassRules: {
        altBg: function (params) {
          return params.node.rowIndex % 2 === 0;
        },
      },
      suppressMenu: true,
      cellStyle: { ...commonCellStyles },
      cellRendererFramework: function (params) {
        return (
          <div className="text-decoration-none cursor-pointer"
            onClick={handleCopyPaste(params.value)}
          >
            {' '}
            {params.value}{' '}
          </div>
        )
      }
    },
    {
      headerName: 'Last Run',
      field: 'pipeline_last_run',
      sortable: true,
      suppressSizeToFit: false,
      sortingOrder: ['asc', 'desc'],
      cellClassRules: {
        altBg: function (params) {
          return params.node.rowIndex % 2 === 0;
        },
      },
      suppressMenu: true,
      cellStyle: { ...commonCellStyles },
      cellRendererFramework: function (params) {
        return (
          <div className="text-decoration-none cursor-pointer"
            onClick={handleCopyPaste(params.value)}
          >
            {' '}
            {params.value}{' '}
          </div>
        )
      }
    },
    {
      headerName: 'Stack Status',
      field: 'stack_status',
      sortable: true,
      suppressSizeToFit: false,
      sortingOrder: ['asc', 'desc'],
      cellClassRules: {
        altBg: function (params) {
          return params.node.rowIndex % 2 === 0;
        },
      },
      suppressMenu: true,
      cellStyle: { ...commonCellStyles },
      cellRendererFramework: function (params) {
        return (
          <div className="text-decoration-none cursor-pointer"
            onClick={handleCopyPaste(params.value)}
          >
            {' '}
            {params.value}{' '}
          </div>
        )
      }
    },
    {
      headerName: 'Pipeline Status',
      field: 'pipeline_status',
      sortable: true,
      suppressSizeToFit: false,
      sortingOrder: ['asc', 'desc'],
      cellClassRules: {
        altBg: function (params) {
          return params.node.rowIndex % 2 === 0;
        },
      },
      suppressMenu: true,
      cellStyle: { ...commonCellStyles },
      cellRendererFramework: function (params) {
        return (
          <div className="text-decoration-none cursor-pointer"
            onClick={handleCopyPaste(params.value)}
          >
            {' '}
            {params.value}{' '}
          </div>
        )
      }
    },
    {
      field: '',
      cellRenderer: 'editActionBtn',
      cellRendererParams: {
        clicked: function (field) {
          if (field.actionBtn === 'edit') {
            alert(`${field} was clicked`);
          }
        },
      },
      suppressSizeToFit: true,
      cellClassRules: {
        altBg: function (params) {
          return params.node.rowIndex % 2 === 0;
        },
      },
      resizable: false
    }
  ];

  const handleOpen = (params) => {
    props.setRequestId(params.data);
    props.handleEditRequestOpen();
  };

  const handleDeleteConfirmation = (props) => {
    setDel(true);
    setId(props.data);
  };

  const handleDelete = () => {
    const { biz_unit, os_type, biz_unit_ami_name } = id;
    deleteRecord(`request/${biz_unit}/${os_type}/${biz_unit_ami_name}`, 'SET_GI_DATA', 'request');
    setDel(false)
    setBackdrop(true);
  };

  const triggerPipeline = (params) => {
    const { biz_unit, os_type, biz_unit_ami_name } = params.data;
    setBackdrop(true);
    getRequest(`bu-gi-execution/${biz_unit}/${os_type}/${biz_unit_ami_name}`);

    setTimeout(() => {
      refreshData();
    }, [3000]);

    setInterval(() => {
      refreshData();
    }, [300000]);
  }

  const openLogs = (params) => {
    props.setRequestId(params.data);
    props.handleLogsOpen();
  }

  const showScript = (params) => {
    props.setRequestId(params.data);
    props.handleScriptOpen();
  }

  const cancelPipeline = (params) => {
    const { biz_unit, os_type, biz_unit_ami_name } = params.data;
    setBackdrop(true);
    getRequest(`bu-gi-cancel-execution/${biz_unit}/${os_type}/${biz_unit_ami_name}`);

    setTimeout(() => {
      refreshData();
    }, [3000]);
  }

  const EditActionBtn = (params) => (
    <TableActionButton
      editProps={params}
      handleOpen={handleOpen}
      handleDeleteConfirmation={handleDeleteConfirmation}
      disabled={params.data.stack_status !== "CREATE_IN_PROGRESS" && params.data.stack_status !== 'UPDATE_IN_PROGRESS' && params.data.pipeline_status !== 'BUILDING' ? false : true}
    >
      <div className="button">
        <Tooltip title="Show Logs" placement="top">
          <IconButton
            style={{ color: "#616161" }}
            className="btn"
            disableRipple
            onClick={() => openLogs(params)}
            size="large">
            <InfoIcon color="inherit" />
          </IconButton>
        </Tooltip>
      </div>
      <div className='button'>
        <Tooltip title="Show Script" placement="top">
          <IconButton
            style={{ color: "#616161" }}
            className="btn"
            disableRipple
            onClick={() => showScript(params)}
            size="large">
            <DescriptionIcon color="inherit" />
          </IconButton>
        </Tooltip>
      </div>
      <div className="button">
        {
          params.data.stack_status === "CREATE_COMPLETE" || params.data.stack_status === 'UPDATE_COMPLETE'
            ?
            (
              <Tooltip title="Run Pipeline" placement="top">
                <IconButton
                  style={{ color: "#616161" }}
                  className="btn"
                  disableRipple
                  onClick={() => triggerPipeline(params)}
                  size="large">
                  <PlayCircleOutlineIcon color="inherit" />
                </IconButton>
              </Tooltip>
            )
            :
            (
              <IconButton
                style={{ color: "#616161" }}
                className="btn"
                disableRipple
                disabled
                onClick={() => triggerPipeline(params)}
                size="large"
              >
                <PlayCircleOutlineIcon color="inherit" />
              </IconButton>
            )
        }
      </div>
      <div className="button">
        <Tooltip title="Cancel Pipeline" placement="top">
          <IconButton
            style={params.data.pipeline_status !== 'BUILDING' ? { color: "#616161" } : { color: "#ff5252" }}
            className="btn"
            disableRipple
            onClick={() => cancelPipeline(params)}
            size="large"
            disabled={params.data.pipeline_status !== 'BUILDING'}
          >
            <StopIcon color="inherit" />
          </IconButton>
        </Tooltip>
      </div>
    </TableActionButton>
  );

  const frameworkComponents = {
    editActionBtn: EditActionBtn
  };

  const value = {
    header: "Golden Images",
    refresh,
    refreshData,
    rowsPerPage,
    setRowsPerPage,
    handleToggleColumnVisibilty,
    dropdownOptions,
    selectedValuesProps: visibilityData.selectedValues,
    setVisibilityToStore,
    handleCreateOpen: props.handleCreateRequestOpen,
    gridApi,
    onFilterTextBoxChanged,
    buttonLabel: "New Request"
  };

  return (
    <>
      {!error ? (<div className="page-table-container">

        <Topbar
          value={value}
        />
        <div
          className="ag-theme-alpine accounts-table"
          style={{ height: '75vh', width: '99%' }}
        >
          {!loading && tableData?.length ? (
            <AgGridReact
              rowHeight={rowHeight}
              columnDefs={columnDefs}
              frameworkComponents={frameworkComponents}
              rowData={tableData}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
              style={style}
              pagination={true}
              paginationPageSize={rowsPerPage}
              ref={gridRef}
              cacheQuickFilter={true}
              headerHeight={60}
            >
            </AgGridReact>
          ) : <Loader />
          }
          <Backdrop
            open={backdropOpen}
            style={{ zIndex: 100 }}
          >
            <CircularProgress
              style={drawerState ? {
                marginLeft: 270
              } : { margin: "0 auto" }}
            />
          </Backdrop>
          <DeleteConfimation
            open={del}
            onClose={() => setDel(false)}
            handleDelete={handleDelete}
            handleCancel={() => setDel(false)}
          />
          <HOVAlert
            open={open}
            onClose={handleSnackClose}
            severity={severity}
            message={message}
            onExit={handleExited}
          />
        </div>
      </div>) : (
        <div
          style={{
            height: '70vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            background: ''
          }}
          className="topbar-container"
        >
          <Error message={message} code={errorCode} />
        </div>)}
    </>
  );
}

export default BUGITable;

const rowHeight = 53.5;

const defaultColDef = {
  resizable: true,
};

const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};