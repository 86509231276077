import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import {
  CircularProgress,
  Backdrop
} from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import { GlobalContext } from '../../App';
import DrawerContext from '../../context/DrawerContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import Error from '../../components/error/Error';
import Loader from '../../components/loader/Loader';
import DeleteConfimation from '../../components/deleteConfirmation/DeleteConfirmation';
import HOVAlert from '../../components/hovAlert/HOVAlert';
import Topbar from '../../components/topbar/Topbar';
import TableActionButton from '../../components/tableActionButtons/TableActionButton';
import useApiCalls from '../../hooks/useApiCalls';

const VPCTable = (props) => {
  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(null);
  const [loading, setLoading] = useState(false);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [tableData, setTableData] = useState();
  const drawerState = useContext(DrawerContext).drawerState;
  const { state, dispatch } = useContext(GlobalContext);
  const [id, setId] = useState(0);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(undefined);
  const [severity, setSeverity] = useState("success");
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [error, setError] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [backdropOpen, setBackdrop] = useState(false);
  const [del, setDel] = useState(false);
  const [snackPack, setSnackPack] = useState([]);
  const {
    getTableData,
    deleteRecord,
    useOpen,
    useSeverity,
    useMessage,
    useErrorCode,
    useHttpStatus
  } = useApiCalls();
  const [style, setStyle] = useState({
    height: '100%',
    width: '99%',
  });
  // eslint-disable-next-line no-unused-vars
  const [dropdownOptions, setDropdownOptions] = useState([
    'VPC ID',
    'ACCOUNT ID',
    'BUSINESS UNIT',
    'REGION',
    'ENVIRONMENT',
    'APP ID'
  ]);
  // eslint-disable-next-line no-unused-vars
  const [visibilityData, setVisibilityData] = useState({
    selectedValues: state.accountsTableColumnVisibility.selectedValues,
    deselectedValues: state.accountsTableColumnVisibility.deselectedValues,
  });

  // Initial api call to get table data
  useEffect(() => {
    let suscribe = true;
    if (suscribe) {
      getTableData('vpc', 'SET_VPC_DATA');
    }

    return () => {
      suscribe = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setQuickFilter(
      document.getElementById('filter-text-box').value
    );
  }, []);

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }

  const handleExited = () => {
    setMessage(undefined);
  };

  const refreshData = () => {
    setRefresh(true);
    getTableData('vpc', 'SET_VPC_DATA');
  };

  useEffect(() => {
    let suscribe = true;
    if (suscribe) {
      props.setSeverity(useSeverity);
      props.setOpen(useOpen);
      props.setMessage(useMessage);

      if (useHttpStatus !== '') {
        setBackdrop(false);
      }
    }

    return () => {
      suscribe = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useSeverity, useMessage, useOpen]);

  useEffect(() => {
    if (useErrorCode) {
      setError(true);
      setErrorCode(useErrorCode);
    }
  }, [useErrorCode]);

  useEffect(() => {
    let suscribe = true;
    if (suscribe) {
      setRefresh(false);
      setLoading(false);
      let tempVpcData = state.vpcData.data?.map((row) => {
        return {
          id: row.id,
          app_id: row.app_id,
          biz_unit: row.biz_unit,
          region: row.region,
          env: row.env,
          ou_path: row.ou_path,
          account_id: row.account_id,
          vpc_id: row.vpc_id,
          domain: row.domain,
        }
      });
      let rdata = tempVpcData?.map((row) => {
        return {
          ...row,
          editActionBtn: { EditActionBtn }
        };
      });
      setTableData(rdata);
    }

    return () => {
      suscribe = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.vpcData])

  useEffect(() => {
    setWidthAndHeight('99%', '70vh');
  }, [drawerState]);

  useEffect(() => {
    if (!gridApi) return;

    gridApi?.sizeColumnsToFit();
  }, [gridApi]);

  useEffect(() => {
    async function setWidthAfterDrawerToggle() {
      await sleep(200);
      gridApi?.sizeColumnsToFit();
    }
    setWidthAfterDrawerToggle();
  });

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

    // on init after leaving page
    state.accountTableColumnVisibility?.selectedValues?.forEach(
      (selectedValue) => toggleColumnsVisibility(selectedValue, true)
    );
    state.accountTableColumnVisibility?.deselctedValues?.forEach(
      (deselctedValue) => toggleColumnsVisibility(deselctedValue, false)
    );
    gridApi?.sizeColumnsToFit();
  };

  const setVisibilityToStore = (visibilityDataa) => {
    dispatch({ type: 'SET_COLUMNS_VISIBILITY', payload: visibilityDataa });
  };

  const setWidthAndHeight = (width, height) => {
    setStyle({
      width,
      height,
    });
  };

  const commonCellStyles = {
    borderRight: '3px solid white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    font: 'normal normal 100 0.9rem/24px Roboto'
  };

  const toggleColumnsVisibility = (column, toggleState) => {
    switch (column) {
      case 'APP ID':
        gridColumnApi?.setColumnVisible('app_id', toggleState);
        break;
      case 'BUSINESS UNIT':
        gridColumnApi?.setColumnVisible('biz_unit', toggleState);
        break;
      case 'REGION':
        gridColumnApi?.setColumnVisible('region', toggleState);
        break;
      case 'ENVIRONMENT':
        gridColumnApi?.setColumnVisible('env', toggleState);
        break;
      case 'ACCOUNT ID':
        gridColumnApi?.setColumnVisible('account_id', toggleState);
        break;
      case 'VPC ID':
        gridColumnApi?.setColumnVisible('vpc_id', toggleState);
        break;

      default:
        break;
    }
  };

  const handleToggleColumnVisibilty = (selectedValues, deselctedValues) => {
    selectedValues.forEach((selectedValue) =>
      toggleColumnsVisibility(selectedValue, true)
    );
    deselctedValues.forEach((deselctedValue) =>
      toggleColumnsVisibility(deselctedValue, false)
    );
    gridApi?.sizeColumnsToFit();
  };

  useEffect(() => {
    if (snackPack.length && !message) {
      // Set a new snack when we don't have an active one
      setMessage({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && message && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackPack, message, open]);

  const handleCopyPaste = (message) => () => {
    navigator.clipboard.writeText(message);
    setSeverity("info");
    setSnackPack((prev) => [...prev, { message: "Text copied", key: new Date().getTime() }]);
  };

  const columnDefs = [
    {
      headerName: 'Account ID',
      field: 'account_id',
      sortable: true,
      suppressSizeToFit: false,
      sortingOrder: ['asc', 'desc'],
      cellClassRules: {
        altBg: function (params) {
          return params.node.rowIndex % 2 === 0;
        },
      },
      suppressMenu: true,
      cellStyle: { ...commonCellStyles },
      cellRendererFramework: function (params) {
        return (
          <div className="text-decoration-none cursor-pointer execution-id"
            onClick={handleCopyPaste(params.value)}
          >
            {' '}
            {params.value}{' '}
          </div>
        )
      }
    },
    {
      headerName: 'Business Unit',
      field: 'biz_unit',
      sortable: true,
      suppressSizeToFit: false,
      sortingOrder: ['asc', 'desc'],
      cellClassRules: {
        altBg: function (params) {
          return params.node.rowIndex % 2 === 0;
        },
      },
      suppressMenu: true,
      cellStyle: {
        ...commonCellStyles
      },
      cellRendererFramework: function (params) {
        return (
          <div className="text-decoration-none cursor-pointer execution-id"
            onClick={handleCopyPaste(params.value)}
          >

            {params.value}
          </div>
        )
      }
    },
    {
      headerName: 'VPC ID',
      field: 'vpc_id',
      sortable: true,
      suppressSizeToFit: false,
      sortingOrder: ['asc', 'desc'],
      cellClassRules: {
        altBg: function (params) {
          return params.node.rowIndex % 2 === 0;
        },
      },
      filter: 'agTextColumnFilter',
      suppressMenu: true,
      cellStyle: { ...commonCellStyles },
      cellRendererFramework: function (params) {
        return (
          <div className="text-decoration-none cursor-pointer execution-id"
            onClick={handleCopyPaste(params.value)}
          >
            {' '}
            {params.value}{' '}
          </div>
        )
      }
    },
    {
      headerName: 'Region',
      field: 'region',
      sortable: true,
      suppressSizeToFit: true,
      sortingOrder: ['asc', 'desc'],
      cellClassRules: {
        altBg: function (params) {
          return params.node.rowIndex % 2 === 0;
        },
      },
      suppressMenu: true,
      cellStyle: { ...commonCellStyles },
      cellRendererFramework: function (params) {
        return (
          <div className="text-decoration-none cursor-pointer execution-id"
            onClick={handleCopyPaste(params.value)}
          >
            {params.value}
          </div>
        )
      }
    },
    {
      headerName: 'Environment',
      field: 'env',
      sortable: true,
      suppressSizeToFit: true,
      sortingOrder: ['asc', 'desc'],
      cellClassRules: {
        altBg: function (params) {
          return params.node.rowIndex % 2 === 0;
        },
      },
      suppressMenu: true,
      cellStyle: {
        ...commonCellStyles,
        textTransform: 'capitalize',
      },
      cellRendererFramework: function (params) {
        return (
          <div className="text-decoration-none cursor-pointer execution-id"
            onClick={handleCopyPaste(params.value)}
          >
            {params.value}
          </div>
        )
      }
    },
    {
      field: '',
      cellRenderer: 'editActionBtn',
      cellRendererParams: {
        clicked: function (field) {
          handleOpen(field)
        },
      },
      suppressSizeToFit: true,
      cellClassRules: {
        altBg: function (params) {
          return params.node.rowIndex % 2 === 0;
        },
      },
      cellStyle: { commonCellStyles },
      resizable: false
    }
  ];


  const handleOpen = (params) => {
    props.handleEditVPCOpen();
    props.setVpcId(params.data.id);
  };

  const EditActionBtn = (editProps) => (
    <TableActionButton
      editProps={editProps}
      handleOpen={handleOpen}
      handleDeleteConfirmation={handleDeleteConfirmation}
    />
  );


  const handleDeleteConfirmation = (props) => {
    setDel(true)
    setId(props.data.id)
  };

  const handleDelete = () => {
    deleteRecord(`vpc/${id}`, 'SET_VPC_DATA', 'vpc');
    setDel(false);
    setBackdrop(true);
  };

  const frameworkComponents = {
    editActionBtn: EditActionBtn
  };

  const value = {
    header: "VPC",
    refresh,
    refreshData,
    rowsPerPage,
    setRowsPerPage,
    handleToggleColumnVisibilty,
    dropdownOptions,
    selectedValuesProps: visibilityData.selectedValues,
    setVisibilityToStore,
    gridApi,
    onFilterTextBoxChanged,
    buttonLabel: "Add VPC",
    handleCreateOpen: props.handleAddVPCOpen
  };

  return (
    <>
      {!error ? (<div className="page-table-container">
        <React.Fragment>
          <Topbar
            value={value}
          />
          <div
            className="ag-theme-alpine accounts-table"
            style={{ height: '75vh', width: '99%' }}
          >
            {!loading && tableData?.length ? (
              <AgGridReact
                rowHeight={rowHeight}
                columnDefs={columnDefs}
                frameworkComponents={frameworkComponents}
                rowData={tableData}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                style={style}
                pagination={true}
                suppressPaginationPanel={false}
                paginationPageSize={rowsPerPage}
                ref={gridRef}
                cacheQuickFilter={true}
                headerHeight={60}
              />
            ) : <Loader />
            }
          </div>
          <Backdrop
            open={backdropOpen}
            style={{ zIndex: 100 }}
          >
            <CircularProgress
              style={drawerState ? {
                marginLeft: 270
              } : { margin: "0 auto" }}
            />
          </Backdrop>
          <DeleteConfimation
            open={del}
            onClose={() => setDel(false)}
            handleDelete={() => handleDelete()}
            handleCancel={() => setDel(false)}
          />
          <HOVAlert
            open={open}
            onClose={handleSnackClose}
            severity={severity}
            message={message}
            onExit={handleExited}
          />
        </React.Fragment>
      </div>) : (
        <div
          style={{
            height: '70vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            background: ''
          }}
          className="topbar-container"
        >
          <Error message={message} code={errorCode} />
        </div>
      )}
    </>
  );
}

export default VPCTable;

const rowHeight = 53.5;

const defaultColDef = {
  resizable: true,
};

const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};