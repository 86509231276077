import React, { useState, useEffect, useContext } from 'react';
import {
  CircularProgress,
  Backdrop,
  Divider,
  Typography
} from '@mui/material';
import Topbar from '../../components/topbar/Topbar';
import { Formik } from 'formik';
import * as Yup from 'yup';
import HOVInput from '../../components/hovInput/HOVInput';
import AutoCompleteWithChips from '../../components/autoCompleteWithChips/AutoCompleteWithChips';
import HOVButton from '../../components/hovButton/HOVButton';
import HOVCheckbox from '../../components/hovCheckbox/HOVCheckbox';
import './Account.scss';
import DrawerContext from '../../context/DrawerContext';
import { GlobalContext } from '../../App';
import useApiCalls from '../../hooks/useApiCalls';


const CreateAccount = (props) => {
  const controlsStyle = { maxWidth: '22vw' };
  const [backdropOpen, setBackdrop] = useState(false);
  const drawerState = useContext(DrawerContext).drawerState;
  const { state } = useContext(GlobalContext);
  const [add, setAdd] = useState(false);
  const {
    addRecord,
    useHttpStatus,
    useMessage,
    useOpen,
    useSeverity
  } = useApiCalls();
  const {
    setOpen,
    setMessage,
    setSeverity,
    handleAddAccountClose,
    create
  } = props;

  useEffect(() => {
    let suscribe = true;
    if (suscribe) {
      if (add) {
        setOpen(useOpen);
        setMessage(useMessage);
        setSeverity(useSeverity);

        if (useHttpStatus === 201) {
          setTimeout(() => {
            setBackdrop(false);
            handleAddAccountClose();
          }, 2000);
        }
        else {
          setBackdrop(false);
        }
      }
    }

    return () => {
      suscribe = false;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useHttpStatus, useMessage, useOpen]);


  const values = {
    header: "Add Account",
    create: create,
    handleClose: handleAddAccountClose
  };

  return (
    <div className="page-table-container">
      <Topbar value={values} />
      <div
        className="form-container"
        style={{ height: 'fit-content', width: '90%' }}
      >
        <Formik
          initialValues={{
            short_name: '',
            account_id: '',
            account_name: '',
            account_owner: '',
            account_env: '',
            account_type: '',
            vertical: '',
            business_unit: state.bizUnits?.data?.length === 1 ? [{
              label: state.bizUnits?.data[0].BusinessUnit,
              value: state.bizUnits?.data[0].BusinessUnit
            }] : [],
            division: '',
            root_email: '',
            patch_management: '',
            okta_url: '',
            costOptimizationEmail: [],
            owca: [],
            servicesHealthEmail: '',
            configAlertEmail: [],
            configAlertEmailCc: [],
            accessApprovers: [],
            accessReportReciepent: [],
            accessReportFrequency: [],
            accessReportSubscription: false,
            businessContacts: [],
            technicalContacts: [],
            ou_name: [],
            ou_id: '',
            regions: [],
            account_status: [],
            business_unit_alignment: '',
            okta_profile_name: '',
            subgroup_name: '',
            bu_gi: false,
            default: [],
            gi: false,
            gi_cleanup: false,
            gi_v2: false,
            gi_v3: false,
            lookup_functions: false,
            workspaces_cost_optimizer: false,
            cfn_hup_interval: '',
            cloud_inventory: false,
            shd: false,
            phd: false,
            ri_notifier: false
          }}
          validationSchema={ValidationSchema}
          onSubmit={(values, actions) => {
            setBackdrop(true);
            setAdd(false);
            try {
              const keys = Object.keys(values);
              const keysLength = keys.length;

              let formatedValues = {
                short_name: '',
                account_id: '',
                account_name: '',
                account_owner: '',
                account_env: '',
                account_type: '',
                vertical: '',
                business_unit: '',
                division: '',
                root_email: '',
                patch_management: '',
                okta_url: '',
                costOptimizationEmail: '',
                owca: '',
                servicesHealthEmail: '',
                configAlertEmail: '',
                configAlertEmailCc: '',
                accessApprovers: '',
                accessReportReciepent: '',
                accessReportFrequency: '',
                accessReportSubscription: false,
                businessContacts: '',
                technicalContacts: '',
                account_status: '',
                default: '',
                ou_name: ''
              };

              let emailValidationList = [
                "owca",
                "configAlertEmail",
                "costOptimizationEmail",
                "accessReportReciepent"
              ];

              let emailValidationListMap = {
                owca: 'OWCA',
                costOptimizationEmail: 'Cost Optimization Email',
                configAlertEmail: 'Config Alerts Email',
                configAlertEmailCc: 'Config Alerts Email CC',
                accessReportReciepent: 'Access Report Recipients'
              }

              // filtering only values and removing labels
              const filteredValues = keys.map((key) => {
                if (values[key] instanceof Array) {
                  return values[key].map(
                    (singleArrayValue) => singleArrayValue.value
                  );
                }
                return values[key];
              });

              // mapping filtered values to formatedValues
              for (let index = 0; index < keysLength; ++index) {
                let key = keys[index];
                if(key==="account_owner"){
                  formatedValues[key] = filteredValues[index]?.toLowerCase();
                }
                else if(key === "businessContacts" || key === "accessApprovers" || key === "technicalContacts"  ){
                  formatedValues[key] = filteredValues[index]?.map((_item)=>_item?.toLowerCase())
                }
                else{
                  formatedValues[key] = filteredValues[index];
                }
                
              }

              // Email validation
              const emailValidationExp = new RegExp(/^#?[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/);

              for (let index = 0; index < emailValidationList.length; index++) {
                if (formatedValues[emailValidationList[index]]) {
                  for (let i = 0; i < formatedValues[emailValidationList[index]].length; i++) {
                    if (!emailValidationExp.test(formatedValues[emailValidationList[index]][i])) {
                      setOpen(true);
                      setMessage(`Please enter valid email id for ${emailValidationListMap[emailValidationList[index]]}`);
                      setSeverity("error");
                      setBackdrop(false);
                      return;
                    }
                  }
                }
              }

              if (!formatedValues['accessReportSubscription']) {
                formatedValues['accessReportSubscription'] = false;
              }

              // For dropdown inputes that accepts single value.
              formatedValues["business_unit"] = formatedValues["business_unit"][0];
              formatedValues["accessReportFrequency"] = formatedValues["accessReportFrequency"][0];
              formatedValues["account_status"] = formatedValues["account_status"][0];
              formatedValues["default"] = formatedValues["default"][0];
              formatedValues["ou_name"] = formatedValues["ou_name"][0];

              // If no option is selected for default GI version
              if (formatedValues["default"] === '') {
                formatedValues["default"] = "gi_v3";
              }

              let modules = {
                account_settings: {
                  cfn_hup_interval: formatedValues["cfn_hup_interval"],
                  default: formatedValues["default"],
                  gi_v2: formatedValues["gi_v2"],
                  gi_v3: formatedValues["gi_v3"],
                  gi_monthly_pinned_release: formatedValues["gi_monthly_pinned_release"]
                },
                bu_gi: formatedValues["bu_gi"],
                cloud_inventory: formatedValues["cloud_inventory"],
                gi: formatedValues["gi"],
                gi_cleanup: formatedValues["gi_cleanup"],
                lookup_functions: formatedValues["lookup_functions"],
                workspaces_cost_optimizer: formatedValues["workspaces_cost_optimizer"],
                shd: formatedValues["shd"],
                phd: formatedValues["phd"],
                ri_notifier: formatedValues["ri_notifier"]
              };

              formatedValues["modules"] = modules;

              // Account Id validation
              const accountIdExp = new RegExp(/^[0-9]+$/)
              if (formatedValues["account_id"].length < 12 || !accountIdExp.test(formatedValues["account_id"])) {
                setOpen(true);
                setMessage("Please enter valid account id");
                setSeverity("error");
                setBackdrop(false);
                return;
              }

              addRecord('accountattributes', formatedValues, 'SET_ACCOUNT_DATA');
              setAdd(true);
            }
            catch (error) {
              console.error(error)
              setBackdrop(false);
              setOpen(true);
              setMessage(error.message);
              setSeverity("error");
            }
          }}
        >
          {(formikProps) => (
            <form onSubmit={formikProps.handleSubmit}>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 col-xl-4 mb-4">
                    <HOVInput
                      label="Short Name"
                      onChangeProp={formikProps.handleChange}
                      onBlurProp={formikProps.handleBlur}
                      valueProp={formikProps.values.short_name}
                      name="short_name"
                      form={formikProps}
                      xstyle={controlsStyle}
                      type="text"
                    />
                    {formikProps.errors.short_name &&
                      formikProps.touched.short_name ? (
                      <div className="text-danger">
                        {formikProps.errors.short_name}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 col-xl-4 mb-4">
                    <HOVInput
                      required
                      label="Account Owner"
                      onChangeProp={formikProps.handleChange}
                      onBlurProp={formikProps.handleBlur}
                      valueProp={formikProps.values.account_owner}
                      name="account_owner"
                      form={formikProps}
                      xstyle={controlsStyle}
                      type="text"
                    />
                    {formikProps.errors.account_owner &&
                      formikProps.touched.account_owner ? (
                      <div className="text-danger">
                        {formikProps.errors.account_owner}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 col-xl-4 mb-4">
                    <HOVInput
                      required
                      label="Vertical"
                      onChangeProp={formikProps.handleChange}
                      onBlurProp={formikProps.handleBlur}
                      valueProp={formikProps.values.vertical}
                      name="vertical"
                      form={formikProps}
                      xstyle={controlsStyle}
                      type="text"
                    />
                    {formikProps.errors.vertical &&
                      formikProps.touched.vertical ? (
                      <div className="text-danger">
                        {formikProps.errors.vertical}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 col-xl-4 mb-4">
                    <HOVInput
                      required
                      label="Okta URL"
                      onChangeProp={formikProps.handleChange}
                      onBlurProp={formikProps.handleBlur}
                      valueProp={formikProps.values.okta_url}
                      name="okta_url"
                      form={formikProps}
                      xstyle={controlsStyle}
                      type="text"
                    />
                    {formikProps.errors.okta_url &&
                      formikProps.touched.okta_url ? (
                      <div className="text-danger">
                        {formikProps.errors.okta_url}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 col-xl-4 mb-4">
                    <HOVInput
                      required
                      label="Account ID"
                      onChangeProp={formikProps.handleChange}
                      onBlurProp={formikProps.handleBlur}
                      valueProp={formikProps.values.account_id}
                      name="account_id"
                      form={formikProps}
                      xstyle={controlsStyle}
                      type="text"
                    />
                    {formikProps.errors.account_id &&
                      formikProps.touched.account_id ? (
                      <div className="text-danger">
                        {formikProps.errors.account_id}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 col-xl-4 mb-4">
                    <HOVInput
                      required
                      label="Account Environment"
                      onChangeProp={formikProps.handleChange}
                      onBlurProp={formikProps.handleBlur}
                      valueProp={formikProps.values.account_env}
                      name="account_env"
                      form={formikProps}
                      xstyle={controlsStyle}
                      type="text"
                    />
                    {formikProps.errors.account_env &&
                      formikProps.touched.account_env ? (
                      <div className="text-danger">
                        {formikProps.errors.account_env}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 col-xl-4 mb-4">
                    <AutoCompleteWithChips
                      label="Business Unit"
                      value={formikProps.values.business_unit}
                      onChange={(e) => formikProps.setFieldValue(`business_unit`, [e])}
                      name="business_unit"
                      option="bu"
                      xstyle={controlsStyle}
                      multi
                      required
                    />
                    {formikProps.errors.business_unit &&
                      formikProps.touched.business_unit ? (
                      <div className="text-danger">
                        {formikProps.errors.business_unit}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 col-xl-4 mb-4">
                    <HOVInput
                      required
                      label="Root Email"
                      onChangeProp={formikProps.handleChange}
                      onBlurProp={formikProps.handleBlur}
                      valueProp={formikProps.values.root_email}
                      name="root_email"
                      form={formikProps}
                      xstyle={controlsStyle}
                      type="text"
                    />
                    {formikProps.errors.root_email &&
                      formikProps.touched.root_email ? (
                      <div className="text-danger">
                        {formikProps.errors.root_email}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 col-xl-4 mb-4">
                    <HOVInput
                      required
                      label="Account Name"
                      onChangeProp={formikProps.handleChange}
                      onBlurProp={formikProps.handleBlur}
                      valueProp={formikProps.values.account_name}
                      name="account_name"
                      form={formikProps}
                      xstyle={controlsStyle}
                      type="text"
                    />
                    {formikProps.errors.account_name &&
                      formikProps.touched.account_name ? (
                      <div className="text-danger">
                        {formikProps.errors.account_name}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 col-xl-4 mb-4">
                    <HOVInput
                      required
                      label="Account Type"
                      onChangeProp={formikProps.handleChange}
                      onBlurProp={formikProps.handleBlur}
                      valueProp={formikProps.values.account_type}
                      name="account_type"
                      form={formikProps}
                      xstyle={controlsStyle}
                      type="text"
                    />
                    {formikProps.errors.account_type &&
                      formikProps.touched.account_type ? (
                      <div className="text-danger">
                        {formikProps.errors.account_type}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 col-xl-4 mb-4">
                    <HOVInput
                      required
                      label="Patch Management"
                      onChangeProp={formikProps.handleChange}
                      onBlurProp={formikProps.handleBlur}
                      valueProp={formikProps.values.patch_management}
                      name="patch_management"
                      form={formikProps}
                      xstyle={controlsStyle}
                      type="text"
                    />
                    {formikProps.errors.patch_management &&
                      formikProps.touched.patch_management ? (
                      <div className="text-danger">
                        {formikProps.errors.patch_management}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 col-xl-4 mb-4">
                    <HOVInput
                      required
                      label="Division"
                      onChangeProp={formikProps.handleChange}
                      onBlurProp={formikProps.handleBlur}
                      valueProp={formikProps.values.division}
                      name="division"
                      form={formikProps}
                      xstyle={controlsStyle}
                      type="text"
                    />
                    {formikProps.errors.division &&
                      formikProps.touched.division ? (
                      <div className="text-danger">
                        {formikProps.errors.division}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 col-xl-4 mb-4">
                    <AutoCompleteWithChips
                      label="Cost Optimization Notification Email(s)"
                      value={formikProps.values.costOptimizationEmail}
                      onChange={(e) =>
                        formikProps.setFieldValue(`costOptimizationEmail`, e)
                      }
                      name="costOptimizationEmail"
                      form={formikProps}
                      xstyle={controlsStyle}
                      type="email"
                      required
                    />
                    {formikProps.errors.costOptimizationEmail &&
                      formikProps.touched.costOptimizationEmail ? (
                      <div className="text-danger">
                        {formikProps.errors.costOptimizationEmail}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 col-xl-4 mb-4">
                    <AutoCompleteWithChips
                      label="OWCA:"
                      value={formikProps.values.owca}
                      onChange={(e) => formikProps.setFieldValue(`owca`, e)}
                      name="owca"
                      form={formikProps}
                      xstyle={controlsStyle}
                      option="email"
                    />
                    {formikProps.errors.owca &&
                      formikProps.touched.owca ? (
                      <div className="text-danger">
                        {formikProps.errors.owca}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 col-xl-4 mb-4">
                    <HOVInput
                      label="Services Health Notification Email"
                      onChangeProp={formikProps.handleChange}
                      onBlurProp={formikProps.handleBlur}
                      valueProp={formikProps.values.servicesHealthEmail}
                      name="servicesHealthEmail"
                      form={formikProps}
                      xstyle={controlsStyle}
                    />
                    {formikProps.errors.servicesHealthEmail &&
                      formikProps.touched.servicesHealthEmail ? (
                      <div className="text-danger">
                        {formikProps.errors.servicesHealthEmail}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 col-xl-4 mb-4">
                    <AutoCompleteWithChips
                      label="Config Alerts Email"
                      value={formikProps.values.configAlertEmail}
                      onChange={(e) =>
                        formikProps.setFieldValue(`configAlertEmail`, e)
                      }
                      name="configAlertEmail"
                      form={formikProps}
                      xstyle={controlsStyle}
                      option="email"
                    />
                    {formikProps.errors.configAlertEmail &&
                      formikProps.touched.configAlertEmail ? (
                      <div className="text-danger">
                        {formikProps.errors.configAlertEmail}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 col-xl-4 mb-4">
                    <AutoCompleteWithChips
                      label="Config Alerts Email Cc"
                      value={formikProps.values.configAlertEmailCc}
                      onChange={(e) =>
                        formikProps.setFieldValue(`configAlertEmailCc`, e)
                      }
                      name="configAlertEmailCc"
                      form={formikProps}
                      xstyle={controlsStyle}
                      option="email"
                    />
                    {formikProps.errors.configAlertEmailCc &&
                      formikProps.touched.configAlertEmailCc ? (
                      <div className="text-danger">
                        {formikProps.errors.configAlertEmailCc}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 col-xl-4 mb-4">
                    <AutoCompleteWithChips
                      label="Access Approvers"
                      value={formikProps.values.accessApprovers}
                      onChange={(e) =>
                        formikProps.setFieldValue(`accessApprovers`, e)
                      }
                      name="accessApprovers"
                      form={formikProps}
                      xstyle={controlsStyle}
                      option="email"
                    />
                    {formikProps.errors.accessApprovers &&
                      formikProps.touched.accessApprovers ? (
                      <div className="text-danger">
                        {formikProps.errors.accessApprovers}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 col-xl-4 mb-4">
                    <AutoCompleteWithChips
                      label="Access Report Recipients"
                      value={formikProps.values.accessReportReciepent}
                      onChange={(e) =>
                        formikProps.setFieldValue(`accessReportReciepent`, e)
                      }
                      name="accessReportReciepent"
                      form={formikProps}
                      xstyle={controlsStyle}
                      option="email"
                    />
                    {formikProps.errors.accessReportReciepent &&
                      formikProps.touched.accessReportReciepent ? (
                      <div className="text-danger">
                        {formikProps.errors.accessReportReciepent}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 col-xl-4 mb-4">
                    <AutoCompleteWithChips
                      label="Access Report Frequency"
                      required
                      value={formikProps.values.accessReportFrequency}
                      onChange={(e) =>
                        formikProps.setFieldValue(`accessReportFrequency`, [e])
                      }
                      name="accessReportFrequency"
                      form={formikProps}
                      xstyle={controlsStyle}
                      option="frequency"
                      multi
                    />
                    {formikProps.errors.accessReportFrequency &&
                      formikProps.touched.accessReportFrequency ? (
                      <div className="text-danger">
                        {formikProps.errors.accessReportFrequency}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 col-xl-4 mb-4 mt-4">
                    <HOVCheckbox
                      value={formikProps.values.accessReportSubscription}
                      onChange={formikProps.handleChange}
                      name="accessReportSubscription"
                      label="Access Report Subscription"
                    />
                  </div>
                  <div className="col-12 col-xl-4 mb-4">
                    <AutoCompleteWithChips
                      label="Business Contacts"
                      value={formikProps.values.businessContacts}
                      onChange={(e) =>
                        formikProps.setFieldValue(`businessContacts`, e)
                      }
                      name="businessContacts"
                      form={formikProps}
                      option="email"
                    />
                    {formikProps.errors.businessContacts &&
                      formikProps.touched.businessContacts ? (
                      <div className="text-danger">
                        {formikProps.errors.businessContacts}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 col-xl-4 mb-4">
                    <AutoCompleteWithChips
                      label="Technical Contacts"
                      value={formikProps.values.technicalContacts}
                      onChange={(e) =>
                        formikProps.setFieldValue(`technicalContacts`, e)
                      }
                      name="technicalContacts"
                      form={formikProps}
                      option="email"
                    />
                    {formikProps.errors.technicalContacts &&
                      formikProps.touched.technicalContacts ? (
                      <div className="text-danger">
                        {formikProps.errors.technicalContacts}
                      </div>
                    ) : null}
                  </div>
                  <Divider className="col-12 col-xl-11 mb-2" />
                  <div className="col-12 col-xl-12 mb-3">
                    <Typography variant='h6'>Modules</Typography>
                  </div>
                  <div className="col-12 col-xl-4 mb-4">
                    <AutoCompleteWithChips
                      label="OU Name"
                      value={formikProps.values.ou_name}
                      onChange={(e) =>
                        formikProps.setFieldValue(`ou_name`, [e])
                      }
                      name="ou_name"
                      form={formikProps}
                      option="ou"
                      xstyle={controlsStyle}
                      required
                      multi
                    />
                    {formikProps.errors.ou_name &&
                      formikProps.touched.ou_name ? (
                      <div className="text-danger">
                        {formikProps.errors.ou_name}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 col-xl-4 mb-4">
                    <AutoCompleteWithChips
                      label="Account Status"
                      required
                      value={formikProps.values.account_status}
                      onChange={(e) =>
                        formikProps.setFieldValue(`account_status`, [e])
                      }
                      name="account_status"
                      form={formikProps}
                      xstyle={controlsStyle}
                      option="accountStatus"
                      multi
                    />
                    {formikProps.errors.account_status &&
                      formikProps.touched.account_status ? (
                      <div className="text-danger">
                        {formikProps.errors.account_status}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 col-xl-4 mb-4">
                    <HOVInput
                      required
                      label="Business Unit Alignment"
                      onChangeProp={formikProps.handleChange}
                      onBlurProp={formikProps.handleBlur}
                      valueProp={formikProps.values.business_unit_alignment}
                      name="business_unit_alignment"
                      form={formikProps}
                      xstyle={controlsStyle}
                      type="text"
                    />
                    {formikProps.errors.business_unit_alignment &&
                      formikProps.touched.business_unit_alignment ? (
                      <div className="text-danger">
                        {formikProps.errors.business_unit_alignment}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 col-xl-4 mb-4">
                    <HOVInput
                      required
                      label="Okta Profile Name"
                      onChangeProp={formikProps.handleChange}
                      onBlurProp={formikProps.handleBlur}
                      valueProp={formikProps.values.okta_profile_name}
                      name="okta_profile_name"
                      form={formikProps}
                      xstyle={controlsStyle}
                      type="text"
                    />
                    {formikProps.errors.okta_profile_name &&
                      formikProps.touched.okta_profile_name ? (
                      <div className="text-danger">
                        {formikProps.errors.okta_profile_name}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 col-xl-4 mb-2">
                    <HOVInput
                      label="Subgroup Name"
                      onChangeProp={formikProps.handleChange}
                      onBlurProp={formikProps.handleBlur}
                      valueProp={formikProps.values.subgroup_name}
                      name="subgroup_name"
                      form={formikProps}
                      xstyle={controlsStyle}
                      type="text"
                    />
                    {formikProps.errors.subgroup_name &&
                      formikProps.touched.subgroup_name ? (
                      <div className="text-danger">
                        {formikProps.errors.subgroup_name}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 col-xl-4 mb-2">
                    <AutoCompleteWithChips
                      label="Regions"
                      value={formikProps.values.regions}
                      onChange={(e) =>
                        formikProps.setFieldValue(`regions`, e)
                      }
                      name="regions"
                      form={formikProps}
                      option="region"
                      required
                    />
                    {formikProps.errors.regions &&
                      formikProps.touched.regions ? (
                      <div className="text-danger">
                        {formikProps.errors.regions}
                      </div>
                    ) : null}
                  </div>
                  <Divider className="col-12 col-xl-11 mb-2" />
                  <div className="col-12 col-xl-12 mb-3">
                    <Typography variant='h6'>Select HOV Features</Typography>
                  </div>
                  <div className="col-12 col-xl-12 mb-2">
                    <HOVCheckbox
                      value={formikProps.values.gi}
                      onChange={formikProps.handleChange}
                      name="gi"
                      label="GI"
                    />
                  </div>
                  <div className="col-12 col-xl-12 mb-2">
                    <HOVCheckbox
                      value={formikProps.values.shd}
                      onChange={formikProps.handleChange}
                      name="shd"
                      label="SHD"
                    />
                  </div>
                  <div className="col-12 col-xl-12 mb-2">
                    <HOVCheckbox
                      value={formikProps.values.phd}
                      onChange={formikProps.handleChange}
                      name="phd"
                      label="PHD"
                    />
                  </div>
                  <div className="col-12 col-xl-12 mb-2">
                    <HOVCheckbox
                      value={formikProps.values.bu_gi}
                      onChange={formikProps.handleChange}
                      name="bu_gi"
                      label="BU GI"
                    />
                  </div>
                  <div className="col-12 col-xl-12 mb-2">
                    <HOVCheckbox
                      value={formikProps.values.gi_cleanup}
                      onChange={formikProps.handleChange}
                      name="gi_cleanup"
                      label="GI Cleanup"
                    />
                  </div>
                  <div className="col-12 col-xl-12 mb-2">
                    <HOVCheckbox
                      value={formikProps.values.ri_notifier}
                      onChange={formikProps.handleChange}
                      name="ri_notifier"
                      label="RI Notifier"
                    />
                  </div>
                  <div className="col-12 col-xl-4 mb-2">
                    <HOVCheckbox
                      value={formikProps.values.lookup_functions}
                      onChange={formikProps.handleChange}
                      name="lookup_functions"
                      label="Lookup Function"
                    />
                  </div>
                  <div className="col-12 col-xl-12 mb-2">
                    <HOVCheckbox
                      value={formikProps.values.cloud_inventory}
                      onChange={formikProps.handleChange}
                      name="cloud_inventory"
                      label="Cloud Inventory"
                    />
                  </div>
                  <div className="col-12 col-xl-12 mb-2">
                    <HOVCheckbox
                      value={formikProps.values.workspaces_cost_optimizer}
                      onChange={formikProps.handleChange}
                      name="workspaces_cost_optimizer"
                      label="Workspaces Cost Optimizer"
                    />
                  </div>
                  <Divider className="col-12 col-xl-11 mb-2" />
                  <div className="col-12 col-xl-12 mb-3">
                    <Typography variant='h6'>Account Settings</Typography>
                  </div>
                  <div className="col-12 col-xl-4 mb-2">
                    <HOVInput
                      label="CFN HUP Interval(in seconds)"
                      onChangeProp={formikProps.handleChange}
                      onBlurProp={formikProps.handleBlur}
                      valueProp={formikProps.values.cfn_hup_interval}
                      name="cfn_hup_interval"
                      form={formikProps}
                      xstyle={controlsStyle}
                      type="text"
                      description="CFN HUP interval in seconds"
                    />
                    {formikProps.errors.cfn_hup_interval &&
                      formikProps.touched.cfn_hup_interval ? (
                      <div className="text-danger">
                        {formikProps.errors.cfn_hup_interval}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 col-xl-4 mb-2">
                    <AutoCompleteWithChips
                      label="Default GI version "
                      value={formikProps.values.default}
                      onChange={(e) =>
                        formikProps.setFieldValue(`default`, [e])
                      }
                      name="default"
                      form={formikProps}
                      xstyle={controlsStyle}
                      option="defaultGIVersion"
                      description="Default GI version to use"
                      multi
                    />
                    {formikProps.errors.default &&
                      formikProps.touched.default ? (
                      <div className="text-danger">
                        {formikProps.errors.default}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 col-xl-12 mb-2">
                    <HOVCheckbox
                      value={formikProps.values.gi_v2}
                      onChange={formikProps.handleChange}
                      name="gi_v2"
                      label="GI v2 "
                      description="Mark true if GI v2 images are present"
                    />
                  </div>
                  <div className="col-12 col-xl-12 mb-2">
                    <HOVCheckbox
                      value={formikProps.values.gi_v3}
                      onChange={formikProps.handleChange}
                      name="gi_v3"
                      label="GI v3 "
                      description="Mark true if GI v3 images are present"
                    />
                  </div>
                  <div className="col-12 col-xl-12 mb-2">
                    <HOVCheckbox
                      value={formikProps.values.gi_monthly_pinned_release}
                      onChange={formikProps.handleChange}
                      name="gi_monthly_pinned_release"
                      label="GI Monthly Pinned Release "
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center h-100 mt-3 mt-lg-0 justify-content-end button-layout">
                  <div>
                    <HOVButton
                      value="Add"
                      color="#fff"
                      backgroundColor="#008542"
                      border="none"
                      className="px-5 py-2"
                      buttonWidth={140}
                      custom
                      type="submit"
                      onClick={formikProps.handleSubmit}
                    />
                  </div>
                  <span className="mx-3"></span>
                  <div>
                    <HOVButton
                      value="Cancel"
                      color="#fff"
                      backgroundColor="#FF4E50"
                      border="none"
                      className="px-5 py-2"
                      buttonWidth={140}
                      custom
                      onClick={(e) => {
                        handleAddAccountClose()
                      }}
                    />
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
        <Backdrop
          open={backdropOpen}
          style={{ zIndex: 100 }}
        >
          <CircularProgress
            style={drawerState ? {
              marginLeft: 200
            } : { margin: "0 auto" }}
          />
        </Backdrop>
      </div>
    </div>
  );
};

export default CreateAccount;
const regex = /^[iI]\d{5,7}$/
const contactValidator = (data)=>{
  try{
    let results = data?.map((item)=>regex?.test(item?.label))
    let isTrue =  !results?.includes(false) ?  true : false
    return isTrue
  }
  catch(error){
    console.error(error)
    return false
  }
}
const ValidationSchema = Yup.object().shape({
  short_name: Yup.string("Please provide account short name").nullable(),
  account_owner: Yup.string().matches(
    regex,
    'It must start with "i" or "I" followed by 5 to 7 digits (e.g., i45678, i3459451)'
  ).required("Please provide account owner name"),
  account_name: Yup.string().required("Please provide account name"),
  account_id: Yup.string().required("Please provide valid account id"),
  vertical: Yup.string().required("Please provide a vertical"),
  okta_url: Yup.string().required("Please provide the okta URL"),
  account_env: Yup.string().required("Please provide the environment"),
  business_unit: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string().required(),
        label: Yup.string().required(),
      })
    ).min(1, 'Please select a business unit.'),
  root_email: Yup.string().email("Please provide valid email id").required('Please provide root email id')
    .matches(/^#?[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/, "Please enter valid email")
    .nullable(),
  account_type: Yup.string().required("Please provide the account type"),
  patch_management: Yup.string().required("Please provide the patch management"),
  division: Yup.string().required("Please provide the division."),
  costOptimizationEmail: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string().required(),
        label: Yup.string().required(),
      })
    )
    .min(1, 'Please provide a valid email'),
  owca: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string().required(),
        label: Yup.string().required(),
      })
    )
    .min(0, 'Please provide a valid email'),
  servicesHealthEmail: Yup.string().email("Please provide valid email id")
    .matches(/^#?[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/, "Please enter valid email")
    .nullable(),
  configAlertEmail: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string().required(),
        label: Yup.string().required(),
      })
    )
    .min(0, 'Please provide a valid email'),
  configAlertEmailCc: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string().required(),
        label: Yup.string().required(),
      })
    )
    .min(0, 'Please provide a valid email'),
  accessApprovers: Yup.array().test({
    name:"accessApprovers",
    message:'It must start with "i" or "I" followed by 5 to 7 digits (e.g., i45678, i3459451)',
    test: (value) => contactValidator(value)
  }),
  accessReportReciepent: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string().required(),
        label: Yup.string().required(),
      })
    )
    .min(0, 'Please provide a valid email'),
  accessReportFrequency: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string().required(),
        label: Yup.string().required(),
      })
    )
    .min(1, 'Please select an option'),
  businessContacts: Yup.array().test({
    name:"businessContacts",
    message:'It must start with "i" or "I" followed by 5 to 7 digits (e.g., i45678, i3459451)',
    test: (value)=> contactValidator(value)
  }),
  technicalContacts: Yup.array().test({
    name:"technicalContacts",
    message:'It must start with "i" or "I" followed by 5 to 7 digits (e.g., i45678, i3459451)',
    test: (value)=> contactValidator(value)
  }),
  ou_name: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string().required(),
        label: Yup.string().required()
      })
    )
    .min(1, "Please select an OU name"),
  account_status: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string().required(),
        label: Yup.string().required(),
      })
    )
    .min(1, "Please select account status"),
  business_unit_alignment: Yup.string().required("Please provide business uni alignment"),
  okta_profile_name: Yup.string().required("Please provide okta profile name"),
  regions: Yup.array().of(
    Yup.object().shape({
      value: Yup.string().required(),
      label: Yup.string().required(),
    })
  ).min(1, 'Please select atleast one region')
});