import React from 'react';
import {
  IconButton,
  Tooltip
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import './TableActionButton.scss';

const TableActionButton = (props) => {
  return (
    <div className="action-btn">
      <div className="button">
        {
          !props.disabled
            ?
            (
              <Tooltip title="Edit" placement="top">
                <IconButton
                  className="btn"
                  disableRipple
                  onClick={() => props.handleOpen(props.editProps)}
                  style={{ color: "#616161" }}
                  size="large">
                  <EditIcon color="inherit" />
                </IconButton>
              </Tooltip>
            )
            :
            (
              <IconButton
                className="btn"
                disableRipple
                disabled
                onClick={() => props.handleOpen(props.editProps)}
                style={{ color: "#616161" }}
                size="large">
                <EditIcon color="inherit" />
              </IconButton>
            )
        }
      </div>
      {
        props.children
      }
      <div className="button">
        <Tooltip title="Delete" placement="top">
          <IconButton
            style={{ color: "#ff5252" }}
            className="btn"
            disableRipple
            onClick={() => props.handleDeleteConfirmation(props.editProps)}
            size="large">
            <DeleteIcon color="inherit" />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};

export default TableActionButton;