import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import {
  CircularProgress,
  Backdrop
} from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import './AccountsTable.scss';
import { GlobalContext } from '../../App';
import DrawerContext from '../../context/DrawerContext';
import Error from '../../components/error/Error';
import Loader from '../../components/loader/Loader';
import DeleteConfimation from '../../components/deleteConfirmation/DeleteConfirmation';
import HOVAlert from '../../components/hovAlert/HOVAlert';
import Topbar from '../../components/topbar/Topbar';
import TableActionButton from '../../components/tableActionButtons/TableActionButton';
import useApiCalls from '../../hooks/useApiCalls';

const AccountsTable = (props) => {
  const gridRef = useRef();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const rowHeight = 53.5;
  const drawerState = useContext(DrawerContext).drawerState;
  const { state, dispatch } = useContext(GlobalContext);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(undefined);
  const [severity, setSeverity] = useState("success");
  const [error, setError] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  const [del, setDel] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [accountid, setAccountid] = useState("");
  const [download, setDownload] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [backdropOpen, setBackdrop] = useState(false);
  const [snackPack, setSnackPack] = useState([]);
  const {
    getTableData,
    deleteRecord,
    useOpen,
    useSeverity,
    useMessage,
    useErrorCode,
    useHttpStatus
  } = useApiCalls();

  // Initial api call to get table data
  useEffect(() => {
    let suscribe = true;
    if (suscribe) {
      getTableData('accountattributes', 'SET_ACCOUNT_DATA');
    }

    return () => {
      suscribe = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [dropdownOptions, setDropdownOptions] = useState([
    'Account Name',
    'Account ID',
    'Account Status'
  ]);

  // eslint-disable-next-line no-unused-vars
  const [visibilityData, setVisibilityData] = useState({
    selectedValues: state.accountsTableColumnVisibility.selectedValues,
    deselectedValues: state.accountsTableColumnVisibility.deselectedValues,
  });

  const setVisibilityToStore = (visibilityDataa) => {
    dispatch({ type: 'SET_COLUMNS_VISIBILITY', payload: visibilityDataa });
  };

  const handleSnackClose = (event, reason) => {
    console.log(reason);
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }

  const handleExited = () => {
    setMessage(undefined);
  };

  const [style, setStyle] = useState({
    height: '100%',
    width: '99%'
  });

  // For refreshing the table data
  const refreshData = () => {
    setRefresh(true);
    getTableData('accountattributes', 'SET_ACCOUNT_DATA');
  };

  useEffect(() => {
    let suscribe = true;
    if (suscribe) {
      props.setSeverity(useSeverity);
      props.setOpen(useOpen);
      props.setMessage(useMessage);

      if (useHttpStatus !== '') {
        setBackdrop(false);
      }
    }

    return () => {
      suscribe = false;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useSeverity, useMessage, useOpen]);

  useEffect(() => {
    if (useErrorCode) {
      setError(true);
      setErrorCode(useErrorCode);
    }
  }, [useErrorCode]);

  useEffect(() => {
    let suscribe = true;
    if (suscribe) {
      setRefresh(false);
      setLoading(false);
      let temp = state.accountsData.data?.map((row) => {
        return {
          accountName: row.account_name,
          accountId: row.account_id,
          account_status: row.account_status
        }
      });

      let rdata = temp?.map((row) => {
        return {
          ...row,
          editActionBtn: { EditActionBtn }
        }
      });

      setTableData(rdata);
    }

    return () => {
      suscribe = false;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.accountsData]);


  const handleModalOpen = (params) => {
    props.handleEditAccountOpen();
    props.getAccountId(params.data.accountId)
  };

  const setWidthAndHeight = (width, height) => {
    setStyle({
      width,
      height
    });
  };

  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  useEffect(() => {
    let suscribe = true;
    if (suscribe) {
      setWidthAndHeight('99%', '70vh');
    }

    return () => {
      suscribe = false;
    }
  }, [drawerState]);

  useEffect(() => {
    gridApi?.sizeColumnsToFit();
  }, [gridApi]);

  useEffect(() => {
    async function setWidthAfterDrawerToggle() {
      await sleep(200);
      gridApi?.sizeColumnsToFit();
    }
    setWidthAfterDrawerToggle();
  });

  const onGridReady = (params) => {
    setGridApi(params?.api);
    setGridColumnApi(params?.columnApi);
    params?.api?.sizeColumnsToFit();

    // on init after leaving page
    state.accountsTableColumnVisibility?.selectedValues?.forEach(
      (selectedValue) => toggleColumnsVisibility(selectedValue, true)
    );
    state.accountsTableColumnVisibility?.deselctedValues?.forEach(
      (deselctedValue) => toggleColumnsVisibility(deselctedValue, false)
    );
    gridApi?.sizeColumnsToFit();
  };

  const toggleColumnsVisibility = (column, toggleState) => {
    switch (column) {
      case 'Account Name':
        gridColumnApi?.setColumnVisible('accountName', toggleState);
        break;
      case 'Account ID':
        gridColumnApi?.setColumnVisible('accountId', toggleState);
        break;
      case 'Account Status':
        gridColumnApi?.setColumnVisible('account_status', toggleState);
        break;

      default:
        break;
    }
  };

  const handleToggleColumnVisibilty = (selectedValues, deselctedValues) => {
    selectedValues.forEach((selectedValue) =>
      toggleColumnsVisibility(selectedValue, true)
    );
    deselctedValues.forEach((deselctedValue) =>
      toggleColumnsVisibility(deselctedValue, false)
    );
    gridApi?.sizeColumnsToFit();
  };

  const EditActionBtn = (editProps) => (
    <TableActionButton
      editProps={editProps}
      handleOpen={handleModalOpen}
      handleDeleteConfirmation={handleDeleteConfirmation}
    />
  );

  const handleDeleteConfirmation = (params) => {
    setDel(true)
    setAccountid(params.data.accountId)
  };

  const handleDelete = () => {
    deleteRecord(`accountattributes/${accountid}`, 'SET_ACCOUNT_DATA', 'accountattributes');
    setDel(false)
    setBackdrop(true);
  };

  const defaultColDef = {
    resizable: true,
    filter: true,
  };

  useEffect(() => {
    if (!download) return;
    gridApi?.exportDataAsCsv();
    setDownload(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [download]);

  const commonCellStyles = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    font: 'normal normal 200 0.9rem/24px Roboto'
  };

  useEffect(() => {
    if (snackPack.length && !message) {
      // Set a new snack when we don't have an active one
      setMessage({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && message && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackPack, message, open]);

  const handleCopyPaste = (message) => () => {
    navigator.clipboard.writeText(message);
    setSeverity("info");
    setSnackPack((prev) => [...prev, { message: "Text copied", key: new Date().getTime() }]);
  };

  const columnDefs = [
    {
      headerName: 'Account Name',
      field: 'accountName',
      sortable: true,
      sortingOrder: ['asc', 'desc'],
      suppressSizeToFit: false,
      cellClassRules: {
        altBg: function (params) {
          return params.node.rowIndex % 2 === 0;
        },
      },
      filter: 'agTextColumnFilter',
      suppressMenu: true,
      cellStyle: {
        ...commonCellStyles,
        textTransform: 'capitalize'
      },
      cellRendererFramework: function (params) {
        return (
          <div className="text-decoration-none cursor-pointer execution-id"
            onClick={handleCopyPaste(params?.value)}
          >
            {params.value}
          </div>
        )
      }
    },
    {
      headerName: 'Account ID',
      field: 'accountId',
      cellRendererParams: {
        clicked: function (field) {
          alert(`${field} was clicked`);
        },
      },
      sortable: true,
      suppressSizeToFit: false,
      sortingOrder: ['asc', 'desc'],
      cellClassRules: {
        altBg: function (params) {
          return params.node.rowIndex % 2 === 0;
        },
      },
      suppressMenu: true,
      cellStyle: {
        ...commonCellStyles,
        textTransform: 'capitalize'
      },
      cellRendererFramework: function (params) {
        return (
          <div className="text-decoration-none cursor-pointer execution-id"
            onClick={handleCopyPaste(params?.value)}
          >
            {' '}
            {params.value}{' '}
          </div>
        )
      }
    },
    {
      headerName: 'Account Status',
      field: 'account_status',
      cellRendererParams: {
        clicked: function (field) {
          alert(`${field} was clicked`);
        },
      },
      sortable: true,
      suppressSizeToFit: false,
      sortingOrder: ['asc', 'desc'],
      cellClassRules: {
        altBg: function (params) {
          return params.node.rowIndex % 2 === 0;
        },
      },
      suppressMenu: true,
      cellStyle: {
        ...commonCellStyles,
        textTransform: 'capitalize'
      },
      cellRendererFramework: function (params) {
        return (
          <div className="text-decoration-none cursor-pointer execution-id"
            onClick={handleCopyPaste(params?.value)}
          >
            {' '}
            {params.value}{' '}
          </div>
        )
      }
    },
    {
      field: '',
      cellRenderer: 'editActionBtn',
      cellRendererParams: {
        clicked: function (field) {
          alert(`${field} was clicked`);
        },
      },
      suppressMenu: true,
      suppressSizeToFit: true,
      cellClassRules: {
        altBg: function (params) {
          return params.node.rowIndex % 2 === 0;
        },
      },
      resizable: false
    }
  ];

  const frameworkComponents = {
    editActionBtn: EditActionBtn
  };

  const postSort = () => {
    gridApi?.redrawRows();
  };

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setQuickFilter(
      document.getElementById('filter-text-box').value
    );
  }, []);

  const values = {
    header: "Accounts",
    handleToggleColumnVisibilty,
    dropdownOptions,
    selectedValuesProps: visibilityData.selectedValues,
    setVisibilityToStore,
    onFilterTextBoxChanged,
    refreshData,
    refresh,
    setRowsPerPage,
    setDownload,
    rowsPerPage,
    handleCreateOpen: props.handleAddAccountOpen,
    buttonLabel: "Add Account",
    gridApi
  }

  return (
    <>{!error ?
      (<div className="page-table-container">
        <Topbar
          value={values}
        />
        <div
          className="ag-theme-alpine accounts-table"
          style={{ height: '75vh', width: '99%' }}
        >
          {!loading && tableData?.length ? (
            <AgGridReact
              postSort={postSort}
              rowHeight={rowHeight}
              columnDefs={columnDefs}
              frameworkComponents={frameworkComponents}
              rowData={tableData}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
              style={style}
              pagination={true}
              paginationPageSize={rowsPerPage}
              headerHeight={60}
              ref={gridRef}
              cacheQuickFilter={true}
            >
            </AgGridReact>
          ) : <Loader />
          }
          <Backdrop
            open={backdropOpen}
            style={{ zIndex: 100 }}
          >
            <CircularProgress
              style={drawerState ? {
                marginLeft: 190
              } : { margin: "0 auto" }}
            />
          </Backdrop>
        </div>
        <DeleteConfimation
          open={del}
          onClose={() => setDel(false)}
          handleDelete={() => handleDelete(accountid)}
          handleCancel={() => setDel(false)}
        />
        <HOVAlert
          open={open}
          onClose={handleSnackClose}
          severity={severity}
          message={message}
          onExit={handleExited}
        />
      </div>) : (
        <div
          style={{
            height: '70vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          className="topbar-container"
        >
          <Error message={message} code={errorCode} />
        </div>
      )}
    </>
  );
};

export default AccountsTable;
