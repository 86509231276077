import React from 'react';
import './HOVCheckbox.scss';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { IconButton, Tooltip } from '@mui/material';

function HOVCheckbox({ label, required, value, onChange, name, description }) {
  return (
    <div className="d-flex align-items-center" style={{margin: "auto 0", height: "42px", width: "90%"}} >
      <div className="hov-checkbox d-block">
        <div className="hov-form-group">
          <input
            type="checkbox"
            id={name}
            defaultChecked={value}
            value={value}
            onChange={onChange}
          />
          <label htmlFor={name} className="d-block"></label>
        </div>
      </div>
      <label htmlFor={name} className="d-block">
        {label}
        {required ? <span className="text-danger">*</span> : null}
        {
          description ? (
            <Tooltip title={description} placement="top">
              <IconButton size="small" style={{ color: "#616161" }} disableRipple>
                <InfoOutlined color='inherit' fontSize='inherit' />
              </IconButton>
            </Tooltip>
          )
            : null
        }
      </label>
    </div>
  );
}

export default HOVCheckbox;